var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50 md-small-size-100" },
        [
          _c(
            "md-card",
            [
              _c("md-card-header", [
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Notifications Style"),
                ]),
              ]),
              _c("md-card-content", [
                _c("div", { staticClass: "alert alert-info" }, [
                  _c("span", [_vm._v("This is a plain notification")]),
                ]),
                _c("div", { staticClass: "alert alert-info" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _vm._v("This is a notification with close button."),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "alert alert-info alert-with-icon",
                    attrs: { "data-notify": "container" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: { type: "button", "aria-hidden": "true" },
                      },
                      [_vm._v("×")]
                    ),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { "data-notify": "icon" },
                      },
                      [_vm._v("add_alert")]
                    ),
                    _c("span", { attrs: { "data-notify": "message" } }, [
                      _vm._v(
                        "This is a notification with close button and icon and have many lines. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "alert alert-rose alert-with-icon",
                    attrs: { "data-notify": "container" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: { type: "button", "aria-hidden": "true" },
                      },
                      [_vm._v("×")]
                    ),
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { "data-notify": "icon" },
                      },
                      [_vm._v("add_alert")]
                    ),
                    _c("span", { attrs: { "data-notify": "message" } }, [
                      _vm._v(
                        'This is a notification with close button and icon and is made with ".alert-rose". You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don\'t have to worry about the style.'
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50 md-small-size-100" },
        [
          _c(
            "md-card",
            [
              _c("md-card-header", [
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Notification states"),
                ]),
              ]),
              _c("md-card-content", [
                _c("div", { staticClass: "alert alert-info" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _c("b", [_vm._v(" Info - ")]),
                    _vm._v(
                      ' This is a regular notification made with ".alert-info"'
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "alert alert-success" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _c("b", [_vm._v(" Success - ")]),
                    _vm._v(
                      ' This is a regular notification made with ".alert-success"'
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "alert alert-warning" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _c("b", [_vm._v(" Warning - ")]),
                    _vm._v(
                      ' This is a regular notification made with ".alert-warning"'
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "alert alert-danger" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _c("b", [_vm._v(" Danger - ")]),
                    _vm._v(
                      ' This is a regular notification made with ".alert-danger"'
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "alert alert-primary" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _c("b", [_vm._v(" Primary - ")]),
                    _vm._v(
                      ' This is a regular notification made with ".alert-primary"'
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "alert alert-rose" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-hidden": "true" },
                    },
                    [_vm._v("×")]
                  ),
                  _c("span", [
                    _c("b", [_vm._v(" Rose - ")]),
                    _vm._v(
                      ' This is a regular notification made with ".alert-rose"'
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "md-card",
            [
              _c("md-card-header", [
                _c("h4", { staticClass: "title text-center" }, [
                  _vm._v("Notifications Places "),
                  _c("p", { staticClass: "category" }, [
                    _vm._v("Click to view notifications"),
                  ]),
                ]),
              ]),
              _c("md-card-content", [
                _c(
                  "div",
                  { staticClass: "places-buttons text-center" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.notifyVue("top", "left")
                          },
                        },
                      },
                      [_vm._v("Top Left")]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.notifyVue("top", "center")
                          },
                        },
                      },
                      [_vm._v("Top Center")]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.notifyVue("top", "right")
                          },
                        },
                      },
                      [_vm._v("Top Right")]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.notifyVue("bottom", "left")
                          },
                        },
                      },
                      [_vm._v("Bottom Left")]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.notifyVue("bottom", "center")
                          },
                        },
                      },
                      [_vm._v("Bottom Center")]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-success",
                        on: {
                          click: function ($event) {
                            return _vm.notifyVue("bottom", "right")
                          },
                        },
                      },
                      [_vm._v("Bottom Right")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("h5", { staticClass: "text-center" }, [
                      _vm._v("Modals"),
                    ]),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary md-round classic-modal",
                        on: {
                          click: function ($event) {
                            _vm.classicModal = true
                          },
                        },
                      },
                      [_vm._v("Classic Modal")]
                    ),
                    _vm.classicModal
                      ? _c(
                          "modal",
                          { on: { close: _vm.classicModalHide } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c("h4", { staticClass: "modal-title" }, [
                                  _vm._v("Modal Title"),
                                ]),
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-simple md-just-icon md-round modal-default-button",
                                    on: { click: _vm.classicModalHide },
                                  },
                                  [_c("md-icon", [_vm._v("clear")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "body" }, [
                              _c("p", [
                                _vm._v(
                                  "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar."
                                ),
                              ]),
                            ]),
                            _c(
                              "template",
                              { slot: "footer" },
                              [
                                _c("md-button", { staticClass: "md-simple" }, [
                                  _vm._v("Nice Button"),
                                ]),
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-danger md-simple",
                                    on: { click: _vm.classicModalHide },
                                  },
                                  [_vm._v("Close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-info md-round notice-modal",
                        on: {
                          click: function ($event) {
                            _vm.noticeModal = true
                          },
                        },
                      },
                      [_vm._v("Notice Modal")]
                    ),
                    _vm.noticeModal
                      ? _c(
                          "modal",
                          { on: { close: _vm.noticeModalHide } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c("h4", { staticClass: "modal-title" }, [
                                  _vm._v("How Do You Become An Affiliate?"),
                                ]),
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-simple md-just-icon md-round modal-default-button",
                                    on: { click: _vm.noticeModalHide },
                                  },
                                  [_c("md-icon", [_vm._v("clear")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "body" }, [
                              _c("div", { staticClass: "instruction" }, [
                                _c("div", { staticClass: "md-layout" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-66",
                                    },
                                    [
                                      _c("strong", [_vm._v("1. Register")]),
                                      _c("p", { staticClass: "description" }, [
                                        _vm._v(
                                          "The first step is to create an account at "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "https://www.creative-tim.com/",
                                            },
                                          },
                                          [_vm._v("Creative Tim")]
                                        ),
                                        _vm._v(
                                          ". You can choose a social network or go for the classic version, whatever works best for you."
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "md-layout-item" }, [
                                    _c("div", { staticClass: "picture" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.registerImg,
                                          alt: "Thumbnail Image",
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "instruction" }, [
                                _c("div", { staticClass: "md-layout" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-66",
                                    },
                                    [
                                      _c("strong", [_vm._v("2. Apply")]),
                                      _c("p", { staticClass: "description" }, [
                                        _vm._v(
                                          "The first step is to create an account at "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "https://www.creative-tim.com/",
                                            },
                                          },
                                          [_vm._v("Creative Tim")]
                                        ),
                                        _vm._v(
                                          ". You can choose a social network or go for the classic version, whatever works best for you."
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "md-layout-item" }, [
                                    _c("div", { staticClass: "picture" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.applyImg,
                                          alt: "Thumbnail Image",
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "If you have more questions, don't hesitate to contact us or send us a tweet @creativetim. We're here to help!"
                                ),
                              ]),
                            ]),
                            _c(
                              "template",
                              { slot: "footer" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-info md-round",
                                    on: { click: _vm.noticeModalHide },
                                  },
                                  [_vm._v("Sound Good")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-rose md-round small-alert-modal",
                        on: {
                          click: function ($event) {
                            _vm.smallAlertModal = true
                          },
                        },
                      },
                      [_vm._v("Small Alert Modal")]
                    ),
                    _vm.smallAlertModal
                      ? _c(
                          "modal",
                          { on: { close: _vm.smallAlertModalHide } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-simple md-just-icon md-round modal-default-button",
                                    on: { click: _vm.smallAlertModalHide },
                                  },
                                  [_c("md-icon", [_vm._v("clear")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "body" }, [
                              _c("p", [
                                _vm._v("Are you sure you want to do this?"),
                              ]),
                            ]),
                            _c(
                              "template",
                              { slot: "footer" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-simple",
                                    on: { click: _vm.smallAlertModalHide },
                                  },
                                  [_vm._v("Never Mind")]
                                ),
                                _c(
                                  "md-button",
                                  { staticClass: "md-success md-simple" },
                                  [_vm._v("Yes")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header text-center" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("Notifications")]),
      _c("p", { staticClass: "category" }, [
        _vm._v("Handcrafted by our friend "),
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://github.com/mouse0270" } },
          [_vm._v("Robert McIntosh")]
        ),
        _vm._v(". Please checkout the "),
        _c(
          "a",
          {
            attrs: {
              href: "https://bootstrap-notify.remabledesigns.com/",
              target: "_blank",
            },
          },
          [_vm._v("full documentation.")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }